<template>
  <div class="ChoosePlan container">
    <section class="section">
      <h1 class="title is-1 has-text-secondary mb-1" v-html="$t('landing.how_many_rides')"/>
      <!--<p>Select your cyclist type so we can find the best Plan for you!</p>-->

      <div class="columns is-mobile">
        <div class="column">
          <b-button size="is-large" label="1-3x" @click="setNbRides(2)" type="is-primary" :outlined="nbRides !== 2"/>
        </div>
        <div class="column">
          <b-button size="is-large" label="4-5x"  @click="setNbRides(4)" type="is-primary" :outlined="nbRides !== 4"/>
        </div>
        <div class="column">
          <b-button size="is-large" label="5+"  @click="setNbRides(6)" type="is-primary" :outlined="nbRides !== 6"/>
        </div>
      </div>
    </section>

    <section class="section">
      <h1 class="title is-1 has-text-secondary mb-1" v-html="$t('landing.kind_of_cyclist')"/>
      <p>{{ $t('landing.select_kind') }}</p>

      <div class="columns is-mobile is-multiline mt-5">
        <div class="column is-half-mobile">
          <div class="sport-button" @click="setType('Competitive')" :class="type === 'Competitive' ? 'selected':''">
            <img src="~@/assets/landing/Cyclist1.png"/>
            <h3 class="sport-button-text title is-3 is-size-5-touch has-text-white">{{ $t('landing.competitive') }}</h3>
          </div>
        </div>
        <div class="column is-half-mobile">
          <div class="sport-button" @click="setType('Fondo')" :class="type === 'Fondo' ? 'selected':''">
            <img src="~@/assets/landing/Cyclist2.png"/>
            <h3 class="sport-button-text title is-3 is-size-5-touch has-text-white">{{ $t('landing.gran_fondo') }}</h3>
          </div>
        </div>
        <div class="column is-half-mobile">
          <div class="sport-button" @click="setType('Fitness')" :class="type === 'Fitness' ? 'selected':''">
            <img src="~@/assets/landing/Cyclist3.png"/>
            <h3 class="sport-button-text title is-3 is-size-5-touch has-text-white">{{ $t('landing.fitness') }}</h3>
          </div>
        </div>
        <div class="column is-half-mobile">
          <div class="sport-button" @click="setType('Improve')" :class="type === 'Improve' ? 'selected':''">
            <img src="~@/assets/landing/Cyclist4.png"/>
            <h3 class="sport-button-text title is-3 is-size-5-touch has-text-white">{{ $t('landing.improve') }}</h3>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>

export default{
  name: 'ChoosePlan',
  data(){
    return {
      nbRides: 0,
      type: '',
    };
  },
  methods: {
    setNbRides(nb){
      this.nbRides = nb;
      if(this.type)
        this.seePlan();
    },
    setType(type){
      this.type = type;
      if(this.nbRides)
        this.seePlan();
    },
    seePlan(){
      this.$router.push('/best_plan?nb_rides='+this.nbRides+'&type='+this.type);
    }
  },

}
</script>

<style lang="scss" scoped>
.sport-button{
  position: relative;
  text-align: center;
  color: white;
  cursor: pointer;
  pointer-events: all;
  transition: all .2s ease-in-out;
}

.sport-button:hover, .sport-button.selected{
  filter: opacity(0.7);
  transform: scale(1.1);
}
.sport-button-text{
  left: 0;
  position:absolute;
  text-align:center;
  bottom: 30px;
  width: 100%;
  word-break: none !important;
}
</style>
